import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// var URL = 'http://localhost:5043/'
var URL = 'https://server.perak.konaweselatankab.go.id/'

export default new Vuex.Store({
  state: {
    url : {
      URL_APP : URL,

      URL_HOME : URL + 'api/v1/publish_home/',
      URL_LOKER : URL + 'api/v1/publish_loker/',
      URL_BERITA : URL + 'api/v1/publish_berita/',
      checkAuth : URL + 'api/v1/checkAuth/',
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
