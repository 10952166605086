import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Navbar from './components/Navbar.vue'
Vue.component('Navbar', Navbar);

import Footer from './components/Footer.vue'
Vue.component('Footer', Footer);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
