<template>
  <div>
	<nav class="navbar main-nav navbar-expand-lg px-2 px-sm-0 py-2 py-lg-0">
		<div class="container">
			<router-link to="/" class="navbar-brand">
				<img src="logo1.png" alt="logo" width="200" height="50">
			</router-link>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
			aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
			<span class="ti-menu"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarNav">
				<ul class="navbar-nav ml-auto">
					<li class="nav-item"><router-link class="nav-link" to="/">Beranda</router-link></li>
					<li class="nav-item"><router-link class="nav-link" to="/berita">Berita</router-link></li>
					<li class="nav-item"><router-link class="nav-link" to="/loker">Loker</router-link></li>
				</ul>
			</div>
		</div>
		</nav>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>