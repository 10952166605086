<template>
  <div>
	<footer>
		<div class="text-center bg-dark py-4">
			<small class="text-secondary">Copyright &copy; 2023. Designed &amp;
				Developed by <a href="#">Disnaker dan Diskominfo Kab. Konawe Selatan</a></small class="text-secondary">
		</div>
	</footer>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>